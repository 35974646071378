export interface IUserSettingsRequest {
    username: string;
    grainId?: string;
    profileId?: string;
    orgId?: string;
    settingName?: string;
}

export interface IUpdateUserSettingsRequest {
    username: string;
    grainId: string;
    profileId: string;
    settingName: string;
    newSetting: { key: string, value: any }[];
}

export interface IUserUpdateLogoRequest {
    username: string;
    logo: string;
}
export interface IUserSettingsResponse {
    settings: string;
}

export interface IAllUserSettingsResponse {
    logo: string;
    scanner_id: ScannerId;
    info_fields: InfoField[];
    scan_dpi: number;
    lightbox_enabled: boolean;
    result_edit_enabled: boolean;
    whiteness_index_enabled: boolean;
    good_and_reject_percent_enabled: boolean;
    rice_profile_selection_enabled: boolean;
    manual_entry_enabled: boolean;
    show_job_history?: boolean;
}

export enum ScannerId {
    DOUBLE_SIDED = "DOUBLE_SIDED",
    EPSON = "EPSON",
    EPSON_V39 = "EPSON_V39",
    MICROTEK_XT3500 = "MICROTEK_XT3500",
}

export interface InfoField {
    fieldName: string;
    displayName: string;
    type: string;
    unit: string;
    category: string;
}

export interface IUserSettings {
    particle_distribution_1: { key: string, value: any }[];
    particle_distribution_2: { key: string, value: any }[];
    particle_distribution_3: { key: string, value: any }[];
    grain_and_profiles: { key: string, value: any }[];
}